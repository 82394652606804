import Vue from 'vue'
import Vuex from 'vuex'
var jwt = require('jsonwebtoken')

import mobileService from '../../services/mobileService';
import licenzeService from '../../services/licenzeService';

Vue.use(Vuex)

function fillDevice(decoded) {
  return {
    base: decoded.base,
    audience: decoded.aud,
    apiKey: decoded.apiKey
  }
}

const mobileToken = JSON.parse(localStorage.getItem('mobile-token'));
var decodedToken
if (mobileToken) {
  decodedToken = jwt.decode(mobileToken)
}

export default {
  namespaced: true,
  state: {
    dashboard: decodedToken ? decodedToken.dashboard : [],
    mobileToken: mobileToken ? mobileToken : null,
    device: decodedToken ? fillDevice(decodedToken) : null,
    token: ''
  },
  getters: {
    mobileToken: state => state.mobileToken,
    token: state => state.token,
    isLoggedIn(state) {
      return !!state.token
    },
    device: state => state.device,
    apiKey: state => state.apiKey
  },
  mutations: {
    setMobileToken(state, token) {
      state.mobileToken = token
      localStorage.setItem('mobile-token', JSON.stringify(token))
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token_bk', JSON.stringify(token))
    },   
    logout(state) {
      state.token = null
      state.device = null
      localStorage.removeItem('mobile-token')
    },
    setDevice(state, device) {
      state.device = device
    }
  },
  actions: {
    async testTokenExpiration({ commit, getters, dispatch}) {
      const mobileToken = getters.mobileToken
      const decodedToken = jwt.decode(mobileToken)
      console.log('testTokenExpiration', decodedToken)
      const now = Math.round(new Date().getTime()/1000)
      let oreToken = Math.round((decodedToken.exp - now) / (60 * 60))
      if (oreToken < 0 ) {
        localStorage.removeItem('mobile-token')
        commit('setDevice', null)
        await dispatch('deviceLogin', { apiKey: decodedToken.apiKey, moduleKey: decodedToken.moduleKey, base: decodedToken.base })
      }
    },
    async deviceLogin({ commit, getters }, { apiKey, moduleKey, base, device, service }) {
      let mobileToken = getters.mobileToken
      if (!mobileToken) {
        if (!apiKey) {
          return
        }
        const token = await licenzeService.getToken(apiKey, base, false)
        commit('setToken', token)
        mobileToken = await mobileService.deviceLogin(apiKey, moduleKey, device, service, token)
      }
      var decoded = jwt.decode(mobileToken)
      console.log('deviceLogin', mobileToken)
      if (decoded) {
        commit('setMobileToken', mobileToken)
        commit('setDevice', fillDevice(decoded))
      }
    },
    userDeviceLogin({commit}, mobileToken) {
      var decoded = jwt.decode(mobileToken)
      console.log('userDeviceLogin', mobileToken)
      if (decoded) {
        commit('setToken', mobileToken)
        commit('setMobileToken', mobileToken)
        commit('setDevice', fillDevice(decoded))
      }
    }
  }
}
