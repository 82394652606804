import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const initialState = JSON.parse(localStorage.getItem('user'));
// salvare refresh token su cookie

export default {
  namespaced: true,
  state: {
    user: initialState ? initialState : null,
    token: initialState ? initialState.token : null
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    user: state => state.user
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      state.token = user.token
      localStorage.setItem('user', JSON.stringify(user))
    },
    setToken(state, token) {
      state.token = token
    },
    logout(state) {
      state.user = null
      state.token = null
      localStorage.removeItem('user')
    }
  },
  actions: {
    async setUser({ commit, getters, dispatch }, user) {
      commit('setUser', user)
      dispatch('mobile/userDeviceLogin', user.token, { root: true })
    }
  }
}
