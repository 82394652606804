<template>
  <v-navigation-drawer v-model="show" fixed temporary @transitionend="transitionend">
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <v-img src="/img/ubisell-logo-round.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{fullname || 'Ubisell Platform'}}</v-list-item-title>
          <v-list-item-subtitle v-if="device || user" ><v-btn text class="text-caption" @click="onLogout">Logout</v-btn></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!device && !user" to="/login" link>
        <v-list-item-icon>
          <v-icon>mdi-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item-content>
      </v-list-item>      
    </template>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item
        v-for="[icon, text, href] in links"
        :to="href"
        :key="icon"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="text-center text-caption">Versione 1.0.4</div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    links: [
      ['mdi-home', 'Home', '/'],
      ['mdi-barcode-scan', 'Picking', '/v-mobile-picking'],
      ['mdi-magnify', 'Ricerca', '/v-mobile-search'],
      ['mdi-store-edit-outline', 'Inventario', '/v-mobile-inventory'],
      ['mdi-account-details-outline', 'Ordini cliente', '/v-ordine-cliente'],
      ['mdi-clipboard-account-outline', 'Ordini fornitore', '/v-ordine-fornitore']
      // ['mdi-inbox-arrow-down', 'Grafici', '/v-grafici'],
      // ['mdi-send', 'Stampe', '/v-stampe']
    ],
    show: false
  }),
  watch: {
    showDrawer(value) {
      console.log('watch left', value)
      if (value) {
        this.show = true
        console.log('dentro value', this.show)
      }
    }
  },
  computed: {
    showDrawer() {
      return this.$store.getters.leftDrawer
    },
    device() {
      return this.$store.getters['mobile/device']
    },
    user() {
      return this.$store.getters['utenti/user']
    },
    fullname() {
      return this.user ? `${this.user.first_name} ${this.user.last_name}` : ''
    }
  },
  methods: {
    onLogout() {
      this.$store.commit('mobile/logout')
      this.$store.commit('utenti/logout')
      this.$router.replace('/').catch(error => {
          console.info(error.message)
      })
    },    
    transitionend() {
      this.$store.commit('SET_LEFT_DRAWER', false)
    },
    toggleRight() {
      // solo per debug poi togliere
      this.$store.commit('SET_RIGHT_DRAWER', true)
    }
  }
}
</script>
