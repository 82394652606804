'use strict'
require('dotenv').config();

const service = process.env.VUE_APP_service_licenze || ''

async function deviceLogin(apiKey, moduleKey, device, isService, token) {
  try {
    const data = {
      apiKey,
      moduleKey,
      device,
      service: isService
    }
    const response = await fetch(`${service}/mobile/device/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    console.log('errore', err)
    return null
  }    
}

async function deviceTokenLogin(registerToken, device, token) {
  try {
    const data = {
      registerToken,
      device
    }
    const response = await fetch(`${service}/mobile/device/token/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    console.log('errore', err)
    return null
  }    
}

export default {
  deviceLogin,
  deviceTokenLogin
}
