import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'
import store from '../store'
import { Role } from '@/helpers/role';
import { Audience } from '@/helpers/audience';

/* import mobile from '../store/mobile';
import mobileService from '../services/mobileService'; */

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeAuth',
    component: () => import(/* webpackChunkName: "homeauth" */ '../views/dashboard'),
    meta: { authorize: [Audience.Dashboard] }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home')
  },    
  { // deprecata --> non devo passare le informazioni della licenza su http
    path: '/:key/:module/:base',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard'),
    meta: { authorize: [Audience.Dashboard] }
  },
  {
    path: '/register/:key/:module/:base',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/dashboard'),
    meta: { authorize: [Audience.Dashboard] }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },   
  {
    path: '/v-stampe',
    name: 'Stampe',
    component: () => import(/* webpackChunkName: "stampe" */ '../views/stampe'),
    meta: { authorize: [Audience.Pdf] }
  },
  {
    path: '/v-mobile-picking',
    name: 'Mobile picking',
    component: () => import(/* webpackChunkName: "picking" */ '../views/picking'),
    meta: { authorize: [] }
  },
  {
    path: '/v-mobile-search',
    name: 'Mobile search',
    component: () => import(/* webpackChunkName: "search" */ '../views/picking/ricerca'),
    meta: { authorize: [] }
  },
  {
    path: '/v-mobile-inventory',
    name: 'Mobile inventory',
    component: () => import(/* webpackChunkName: "search" */ '../views/picking/inventario'),
    meta: { authorize: [] }
  },    
  {
    path: '/v-ordine-cliente',
    name: 'Ordine cliente',
    component: () => import(/* webpackChunkName: "ordineCliente" */ '../views/ordineCliente'),
    meta: { authorize: [] }
  },
  {
    path: '/v-ordine-fornitore',
    name: 'Ordine fornitore',
    component: () => import(/* webpackChunkName: "ordineFornitore" */ '../views/ordineFornitore'),
    meta: { authorize: [] }
  },    
  

  // {
  //   path: '/v-clienti',
  //  name: 'Clienti',
  //  component: () => import(/* webpackChunkName: "clienti" */ '../views/clienti'),
  //  meta: { authorize: [Role.Admin] }
  //},
  //{
  //  path: '/v-service',
  //  name: 'Service',
  //  component: () => import(/* webpackChunkName: "service" */ '../views/service'),
  //  meta: { authorize: [Role.Admin] }
  //}, 
  //{
  //  path: '/v-configurazione',
  //  name: 'Configurazione',
  //  component: () => import(/* webpackChunkName: "configurazione" */ '../views/configurazione'),
  //  meta: { authorize: [Role.Admin] }
  //},  
 //  {
 //    path: '/pdf',
 //    name: 'Pdf',
 //    component: () => import(/* webpackChunkName: "pdf" */ '../views/pdfViewer/viewer.vue'),
 //    props: route => ({ source: route.params.source })
 //  },    
 //  {
 //    path: '/login',
 //    component: () => import(/* webpackChunkName: "login" */ '../views/login')
 //  },
  // {
  //   path: '/token',
  //   name: 'Token',
  //   component: () => import(/* webpackChunkName: "token" */ '../views/licenza/tokenScaduto.vue')
  // },
  {
    path: '/licenza',
    name: 'Licenza',
    component: () => import(/* webpackChunkName: "licenza" */ '../views/licenza/licenzaScaduta.vue')
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  let currentDevice = store.getters['mobile/device']
  if (authorize) {
    if (!currentDevice || to.name === 'Register') {
      await store.dispatch('mobile/deviceLogin', { apiKey: to.params.key, moduleKey: to.params.module, base: to.params.base, device: to.params.device, service: to.query.service === 'true'})
      // await store.dispatch('mobile/deviceLoginToken', { registerToken: to.query.tk, device: to.params.device })
      currentDevice = store.getters['mobile/device']
    } else {
      await store.dispatch('mobile/testTokenExpiration')
    }
    if (!currentDevice || (authorize.length && !_.intersection(authorize, currentDevice.audience).length)) {
      return next({ path: '/home' });
    }
  }

  next()
})

export default router
